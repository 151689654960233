import { Injectable } from '@angular/core';
import { Commands } from '@commands/core/base.command';
import { AuthService } from '@core/network/auth.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GetClientTokenCommand implements Commands {
  constructor(
    private authService: AuthService
  ) {}

  public exec(): Observable<string> {
    return this.authService.getClientToken();
  }
}
