import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivationEnd, Router } from '@angular/router';
import { ToggleDrawableMenuCommand } from '@commands/ui/toggle-drawable-menu.command';
import { HeaderRoutingInterface } from '@core/models/header-routing.interface';
import { Observable, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit, OnDestroy {
  public headerRouting$!: Observable<HeaderRoutingInterface>;
  public destroy$ = new Subject<boolean>();

  constructor(
    private route: Router,
    private toggleDrawableMenuCommand: ToggleDrawableMenuCommand
  ) {}

  public ngOnInit(): void {
    this.headerRouting$ = this.route.events.pipe(
      takeUntil(this.destroy$),
      filter((e) => e instanceof ActivationEnd),
      map((e: any) => e.snapshot.data.header)
    );
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public openDrawerMenu(): void {
    this.toggleDrawableMenuCommand.exec();
  }
}
