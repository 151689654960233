<nz-layout>
  <app-header></app-header>
  <nz-content>
    <div class="inner-content">
      <router-outlet></router-outlet>
    </div>
  </nz-content>
  <app-footer></app-footer>
  <app-drawer-menu></app-drawer-menu>
</nz-layout>
