import { Injectable } from '@angular/core';
import { Commands } from '@commands/core/base.command';
import { AuthStore } from '@core/store/auth/auth.store';

@Injectable({
  providedIn: 'root',
})
export class SetTokenToStoreCommand implements Commands {
  constructor(private authStore: AuthStore) {}

  public exec(token: string): void {
    this.authStore.update({ token });
  }
}
