import {
  HttpEvent, HttpHandler, HttpInterceptor, HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { AuthStore } from '@core/store/auth/auth.store';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authStore: AuthStore, private transferState: TransferState) {}

  public _key = makeStateKey('data-loaded-state-token');

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const tokenFront = this.transferState.get<boolean>(this._key, false);
    if (tokenFront) {
      this.authStore.update({ token: tokenFront.toString() });
    }

    const token = this.authStore.getValue().token;
    const isApiUrl = request.url.startsWith(environment.api_path + 'v1/');
  
    if (token && isApiUrl) {
      request = request.clone({
        setHeaders: { Authorization: `Bearer ${token}` },
      });
    }
    return next.handle(request);
  }
}
