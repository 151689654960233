<div nz-row id="reviewFooter">
    <nz-col nzSpan="16">
        <app-review></app-review>
    </nz-col>
</div>
<nz-footer>
    <div nz-row nzJustify="center" id="footerContainer">
        <div nz-col nzLg="5" nzXl="5" nzXs="24" nzSm="24" id="footerContainer-logo">
            <img src="/assets/images/logo-text.svg" alt="Schmidt" />
            <ul>
                <li><a [href]="'tel:'+('COMMON.PHONE_NUMBER' | translate)">T. {{ 'COMMON.PHONE_NUMBER' | translate }}</a></li>
                <li><a [href]="'mailto:'+('COMMON.EMAIL' | translate)">{{ 'COMMON.EMAIL' | translate }}</a></li>
            </ul>
        </div>
        <div nz-col nzLg="5" nzXl="5" nzXs="24" nzSm="24" id="footerContainer-location">
            <app-agence-location-list></app-agence-location-list>

            <div id="footerContainer-location-hours">
                <div id="footerContainer-location-hours-title">{{ 'FOOTER.PRINCIPAL.HOUR.TITLE' | translate }}</div>
                <ul>
                    <li><span class="dayLi">Lun.</span><span>09h00 à 13h00 - 14h00 à 18h00</span></li>
                    <li><span class="dayLi">Mar.</span><span>09h00 à 13h00 - 14h00 à 18h00</span></li>
                    <li><span class="dayLi">Mer.</span><span>09h00 à 13h00 - 14h00 à 18h00</span></li>
                    <li><span class="dayLi">Jeu.</span><span>09h00 à 13h00 - 14h00 à 18h00</span></li>
                    <li><span class="dayLi">Ven.</span><span>09h00 à 13h00 - 14h00 à 18h00</span></li>
                    <li><span class="dayLi">Sam.</span><span>10h00 à 13h00</span></li>
                </ul>
            </div>
        </div>
        <div nz-col nzLg="5" nzXl="5" nzXs="24" nzSm="24" id="footerContainer-agents">
            <div>
                <p>Nos agents agréés I.P.I.</p>
                <ul>
                    <p>Belgique</p>
                    <li>508.262 Kathy Salvo</li>
                    <li>104.125 Christian Schmidt</li>
                    <li>512.929 Manon Jordant</li>
                    <li>514.342 Victoria Gérard</li>
                    <li>517.875 Margaux de Madre </li>
                </ul>
            </div>
            <div id="footerContainer-agents-controle">
                <ul>
                    <p>Instance officielle de contrôle</p>
                    <li>Institut professionnel des agents immobiliers</li>
                    <li>Rue du Luxembourg 16 B | 1000 Bruxelles</li>
                    <li>Politique de confidentialité | <a href="https://www.ipi.be/agent-immobilier-ipi/la-deontologie-de-lagent-immobilier" target="_blank">Code de déontologie</a></li>
                    <li>www.ipi.be</li>
                </ul>
            </div>
            <div>
                <ul>
                    <p>RC professionnelle et cautionnement</p>
                    <li>AXA Belgium SA | Police N° 730.404.407/0158 et 730.404.407/0230</li>
                </ul>
            </div>
        </div>
        <div nz-col nzLg="4" nzXl="4" nzXs="24" nzSm="24" id="footerContainer-buttons">
            <a
                [nzSize]="'large'"
                nz-button
                (click)="scrollTop()"
                nzType="primary"
                nzShape="round"
                [routerLink]="['/contact']"
            >{{ 'HEADER.ACTIONS.ESTIMATE_HOME' | translate }}</a>
            <a
                [nzSize]="'large'"
                nz-button
                (click)="scrollTop()"
                nzType="primary"
                nzShape="round"
                [routerLink]="['/contact']"
            >{{ 'COMMON.TAKE_RDV' | translate }}</a>
            <a nzGhost
                [nzSize]="'large'"
                nz-button
                nzType="primary"
                nzShape="round"
                target="_blank" href="https://owner-whise.webulous.be/kAzr2E77iP"
            >{{ 'COMMON.LOGIN_PROPRIO' | translate }}</a>
            <div>
                <p>{{ 'FOOTER.PRINCIPAL.FOLLOW_US.TITLE' | translate }}</p>
                <a href="https://www.facebook.com/Schmidtimmo" target="_blank"><i class="icon-facebook"></i></a>
                <a href="https://www.instagram.com/schmidt_immo/" target="_blank"><i class="icon-instagram"></i></a>
            </div>
        </div>
    </div>
</nz-footer>
<div nz-row nzJustify="center" id="subFooter">
    <span>{{ 'FOOTER.SUB_FOOTER.COPYRIGHT' | translate }} </span>
    <span>{{ 'FOOTER.SUB_FOOTER.COOKIES' | translate }} </span>
    <a href="/assets/files/politique-de-confidentialite.pdf" target="_blank">{{ 'FOOTER.SUB_FOOTER.CONFIDIENTIALITY' | translate }} </a>
    <span [innerHTML]="'FOOTER.SUB_FOOTER.DEVELOPED_BY' | translate"></span>
</div>
