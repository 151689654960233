import { makeStateKey, TransferState } from "@angular/platform-browser";
import { GetClientTokenCommand, GetTokenCommand } from "@commands/network";
import { HttpSideEnum } from "@core/enums";
import * as memoryCache from 'memory-cache';
import { switchMap } from "rxjs";

export function appInitializer(
  getTokenCommand: GetTokenCommand,
  getClientTokenCommand: GetClientTokenCommand,
  transferState: TransferState,
  isServer: string
) {
  const _key = makeStateKey('data-loaded-state-token');
  //const cachedToken = memoryCache.get('token');
  const cachedToken: any = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiIsImlhdCI6MTY1ODQ4NzIzNn0.eyJzZXJ2aWNlQ29uc3VtZXJJZCI6NjgzLCJ0eXBlSWQiOjQsImNsaWVudElkIjo2MjZ9.KOsineCuYvjC5fM8nWNXcfPgXsA0kIeuse9qnEi4ZT0';

  //if (isServer === HttpSideEnum.SERVER && cachedToken) {
    transferState.set(_key, cachedToken);
  //}

 /* if (!cachedToken && isServer === HttpSideEnum.SERVER) {
    return () =>
      new Promise((resolve) => {
        getTokenCommand.exec().pipe(switchMap(e => getClientTokenCommand.exec())).subscribe((e) => {
            transferState.set<string>(_key, e);
            memoryCache.put('token', e);
            resolve(e);
        });
      });
  } else {*/
    return () => new Promise((resolve) => resolve(true));
  //}
}
