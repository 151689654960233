import { Injectable } from '@angular/core';
import { Commands } from '@commands/core/base.command';
import { EstateSearch } from '@core/models';
import { EstatePagination } from '@core/models/estate-pagination.model';
import { EstateService } from '@core/network/estate.service';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SearchEstateCommand implements Commands {
  constructor(private estateService: EstateService) {}

  public exec(search: EstateSearch, estates?: EstatePagination): Observable<EstatePagination> {
      return this.estateService.getEstates(search).pipe(
        map((estate) => {
          if (estates && estates.estates.length > 0) {
            estates.estates.push(...estate.estates);
            return estates;
          }
          return estate;
        })
      );
  }
}
