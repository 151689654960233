export const RangePrice = [
    { from: 1, to: 50000, },
    { from: 50000, to: 100000 },
    { from: 100000, to: 150000 },
    { from: 150000, to: 200000 },
    { from: 200000, to: 250000 },
    { from: 250000, to: 300000 },
    { from: 300000, to: 350000 },
    { from: 350000, to: 400000 },
    { from: 400000, to: 450000 },
    { from: 450000, to: 500000 },
    { from: 500000, to: 999999999 }
];

