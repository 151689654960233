import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import fr from '@angular/common/locales/fr';
import { Inject, Injector, NgModule, PLATFORM_ID } from '@angular/core';
import { TransferState } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { setAppInjector } from '@shared/utils/app-injector';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { environment } from 'src/environments/environment';
import { components } from './components';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'https://schmidtwallonie.be/assets/i18n/', '.json?v=' + Date.now());
}

registerLocaleData(fr);

@NgModule({
  declarations: [components],
  imports: [
    CommonModule,
    RouterModule,
    NzSpaceModule,
    NzDrawerModule,
    NzButtonModule,
    NzLayoutModule,
    NzMenuModule,
    NzGridModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      isolate: false,
    }),
  ],
  exports: [
    TranslateModule,
    components,
    NzLayoutModule,
    NzButtonModule,
    NzSpaceModule,
    NzGridModule,
  ],
})
export class CoreModule {
  constructor(injector: Injector) {
    setAppInjector(injector);
  }
}
