import { Injectable } from '@angular/core';
import { Commands } from '@commands/core/base.command';
import { EstatePurpose } from '@core/enums';
import { EstateSearch } from '@core/models';
import { XsellHome } from '@core/models/xsell-home.interface';
import { EstateService } from '@core/network/estate.service';
import { map, Observable, zip } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GetXsellEstateHomeCommand implements Commands {
  constructor(private estateService: EstateService) {}

  public exec(): Observable<XsellHome> {
    const estateSearchSell = new EstateSearch({
      status: ['FOR_SALE', 'LIFE_ANNUITY_SALE'],
      page: 0,
      limit: 6,
    });
    const estateSearchRent = new EstateSearch({
      status: ['FOR_RENT'],
      page: 0,
      limit: 6,
    });
    return zip(
      this.estateService.getEstates(estateSearchSell),
      this.estateService.getEstates(estateSearchRent)
    ).pipe(
      map(([sell, rent]) => {
        return {
          [EstatePurpose.FOR_SALE]: sell.estates,
          [EstatePurpose.FOR_RENT]: rent.estates,
        };
      })
    );
  }
}
