import { ChangeDetectionStrategy, Component } from '@angular/core';

export const locationsAgences = [
    {
      title: 'Aywaille',
      address: 'Avenue Louis Libert 1 | 4920 Aywaille',
      code: 'BCE0664560163'
    },
    {
      title: 'Sprimont - Dolembreux',
      address: 'Rue Piretfontaine 6B | 4140 Sprimont',
      code: 'BCE0717899176'
    }
  ];

@Component({
  selector: 'app-agence-location-list',
  templateUrl: './agence-location-list.component.html',
  styleUrls: ['./agence-location-list.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgenceLocationListComponent {
  public locations = locationsAgences;
}
