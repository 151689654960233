<nz-row id="subheader" [ngSwitch]="headerRouting.type">
    <div nz-col nzSpan="24" *ngSwitchCase="headerType.BASIC" id="subheader-basic{{ '-'+headerRouting.name | lowercase }}">
        <div class="subheader-titles">
            <h1>{{ headerRouting.name + '.SUB_HEADER.TITLE' | translate }}</h1>
            <h2>{{ headerRouting.name + '.SUB_HEADER.SUB_TITLE' | translate }}</h2>
        </div>
    </div>
    <div nz-col nzSpan="24" *ngSwitchCase="headerType.FICHE" id="subheader-fiche">
        <div class="subheader-titles">

        </div>
    </div>
</nz-row>