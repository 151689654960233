import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { HeaderTypeEnum } from '@core/enums';
import { HeaderRoutingInterface } from '@core/models/header-routing.interface';


@Component({
  selector: 'app-sub-header',
  templateUrl: './sub-header.component.html',
  styleUrls: ['./sub-header.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubHeaderComponent implements OnInit {
  @Input() headerRouting!: HeaderRoutingInterface;

  public headerType = HeaderTypeEnum;

  constructor() {}

  ngOnInit(): void {}
}
