import { Estate } from "./estate.model";

export class EstatePagination {
  public estates!: Estate[];
  public totalCount!: number;

  constructor(model: Partial<EstatePagination>) {
    Object.assign(this, model);
  }

  public static fromDto(dto: any): EstatePagination {
    return new EstatePagination({
      estates: dto.estates ? dto.estates.map((estate: any) => Estate.fromDto(estate)) : [],
      totalCount: dto.totalCount || 0
    });
  }
}