import { Injectable } from '@angular/core';
import { Commands } from '@commands/core/base.command';
import { AuthService } from '@core/network/auth.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GetTokenCommand implements Commands {
  constructor(
    private authService: AuthService
  ) {}

  public exec(): Observable<string> {
    return this.authService.getToken({
      username: environment.auth.username,
      password: environment.auth.password,
    });
  }
}
