import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EmailModel, EstateLocation, EstatePagination, EstateSearch } from '@core/models';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EmailService {
  constructor(private http: HttpClient) {}

  public sendEmailContact(
    email: EmailModel
  ): Observable<void> {
    return this.http.post<void>('https://findyourplace.be/fr/api/schmidt/contact', email.toDto())
  }
}
