import { Params } from '@angular/router';
import {
  EstateCategory,
  EstateCategoryMappingNumber, EstatePurpose,
  EstatePurposeMappingNumber
} from '@core/enums';
import { RangePrice } from '@core/enums/range-price.enum';

export class EstateSearch {
  public id?: string;
  public priceMin?: number;
  public priceMax?: number;
  public ref?: string;
  public location?: string;
  public status?: EstatePurpose[]|string[];
  public type?: EstateCategory[]|string[];
  public orderPrice?: boolean;
  public orderDate?: boolean = true;

  public page?: number = 0;
  public limit?: number = 9;
  public offset?: number = 0;

  constructor(model: Partial<EstateSearch>) {
    Object.assign(this, model);
    this.status = this.status
      ? typeof this.status === 'string'
        ? [this.status]
        : this.status
      : [];

    if (+model.priceMin! > 0) {
      this.priceMin = +model.priceMin!;
      if (!model.priceMax) {
        this.priceMax = RangePrice.find(e => e.from === this.priceMin)?.to;
      }
    }

    this.type = this.type
      ? typeof this.type === 'string'
        ? [this.type]
        : this.type
      : [];
  }

  public static fromQueryUrl(url: Params): EstateSearch {
    return new EstateSearch({
      priceMin: url['priceMin'] || null,
      priceMax: url['priceMax'] || null,
      orderDate: url['orderDate'] || null,
      orderPrice: url['orderPrice'] || null,
      status: url['status'] || null,
      type: url['type'] || null,
      location: url['location'] || null,
      page: url['page'] || 0,
      limit: 10,
      offset: 0,
    });
  }

  public toDto(isForFiche: boolean = false): any {
    const orderPrice = this.orderPrice ? [{'Field': 'price', 'Ascending': true}] : [];
    const orderDate = this.orderDate ? [{'Field': 'putOnlineDateTime', 'Ascending': false}] : [];
    const purposeId = this.status ? this.status.map((e: any) => EstatePurposeMappingNumber.indexOf(EstatePurpose[e as keyof typeof EstatePurpose])) : [];
    const defaultOrder = (!orderPrice.length && !orderDate.length) ? [{'Field': 'id', 'Ascending': true}] : [];

    const data: any = {
      Page: {
        Page: this.page || 0,
        Limit: this.limit || 10,
        Offset: this.offset || 0,
      },
      Filter: {
        PriceRange: {
          Min: this.priceMin || 1,
          Max: this.priceMax || 999999999,
        },
        LanguageId: 'fr-BE',
        ReferenceNumber: this.ref || null,
        EstateIds: this.id ? [this.id] : null,
        ZipCodes: [this.location] || null,
        PurposeIds: purposeId.filter(e => e !== 4 && e !== 6),
        CategoryIds: this.type ? this.type.map((e: any) => EstateCategoryMappingNumber.indexOf(EstateCategory[e as keyof typeof EstateCategory])) : [],
      },
      Sort: [
        ...defaultOrder,
        ...orderPrice,
        ...orderDate
      ]
    };

    if (!isForFiche) {
      if (purposeId.length) {
        switch (purposeId[0]) {
          case 1: // A vendre
            data.Filter.PurposeStatusIds = [1, 5, 15];
            data.Filter.StatusIds = [1];
            data.Filter.DisplayStatusIds = [2];
            break;
          case 2: // A louer
            data.Filter.PurposeStatusIds = [2,6];
            data.Filter.StatusIds = [1];
            data.Filter.DisplayStatusIds = [2];
            break;
          case 4: // viage
            data.Filter.PurposeStatusIds = [3];
            data.Filter.StatusIds = [1];
            data.Filter.DisplayStatusIds = [2,4];
            break;
          case 6: // vendu
            data.Filter.PurposeStatusIds = [3, 14, 17];
            data.Filter.StatusIds = [1];
            data.Filter.DisplayStatusIds = [2, 4];
            break;
          default:
            data.Filter.PurposeStatusIds = [1, 5, 15];
            data.Filter.StatusIds = [1];
            data.Filter.DisplayStatusIds = [2];
          break;
        }
      } else {
        data.Filter.PurposeStatusIds = [1,5, 15];
        data.Filter.StatusIds = [1];
        data.Filter.DisplayStatusIds = [2];
      }
    }

    Object.keys(data).forEach((k) => data[k] == null && delete data[k]);
    return data;
  }

  public toForm(): any {
    const data: any = {
      priceMin: this.priceMin ? +this.priceMin : null,
      orderDate: (this.orderDate || (!this.orderDate && !this.orderPrice)) ? 'orderDate' : null,
      orderPrice: this.orderPrice ? 'orderPrice' : null,
      status: this.status ? this.status[0] : null,
      type: this.type ? this.type[0] : null,
      location: this.location || null,
      page: this.page || 0
    };
    Object.keys(data).forEach((k) => data[k] == null && delete data[k]);
    return data;
  }

  public toRouteQuery(): any {
    const data: any = {
      priceMin: this.priceMin || null,
      orderDate: this.orderDate ? 'orderDate' : null,
      orderPrice: this.orderPrice ? 'orderPrice' : null,
      status: this.status ? this.status[0] : null,
      type: this.type ? this.type[0] : null,
      location: this.location || null
    };
    return data;
  }
}
