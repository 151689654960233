import { Injectable } from '@angular/core';
import { Commands } from '@commands/core/base.command';
import { Estate, EstateSearch } from '@core/models';
import { EstateService } from '@core/network/estate.service';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GetEstateByIdCommand implements Commands {
  constructor(private estateService: EstateService) {}

  public exec(id: string): Observable<Estate> {
    const search = new EstateSearch({ id, limit: 1 });
    return this.estateService
      .getEstates(search, true)
      .pipe(map((estate) => estate.estates[0]));
  }
}
