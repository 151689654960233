import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EstateLocation, EstatePagination, EstateSearch } from '@core/models';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EstateService {
  constructor(private http: HttpClient) {}

  public getEstates(
    estateSearch: EstateSearch,
    fromFiche: boolean = false
  ): Observable<EstatePagination> {
    return this.http
      .post(environment.api_path + 'v1/estates/list', estateSearch.toDto(fromFiche))
      .pipe(map((estates: any) => EstatePagination.fromDto(estates)));
  }

  public getAllLocationByStatus(status: number): Observable<EstateLocation[]> {
    return this.http
      .post(environment.api_path + 'v1/estates/usedcities/list', {EstateFilter: {PurposeStatusIds: [status], LanguageId: 'fr-FR'}, })
      .pipe(map((locations: any) => locations.cities.map((location: any) => EstateLocation.fromDto(location))));
  }
}
