// status
export enum EstatePurpose {
  NONE = '',
  FOR_SALE = 'for sale',
  FOR_RENT = 'for rent',
  LIFE_ANNUITY_SALE = 'life annuity sale',
  UNDER_OFFER_SALE = 'under offer (sale)',
  UNDER_OFFER_RENT = 'under offer (rent)',
  SOLD = 'vendu'
}

export const EstatePurposeToDto: any = {
  [EstatePurpose.FOR_SALE]: EstatePurpose.FOR_SALE,
  [EstatePurpose.FOR_RENT]: EstatePurpose.FOR_RENT,
  [EstatePurpose.UNDER_OFFER_RENT]: EstatePurpose.UNDER_OFFER_RENT,
  [EstatePurpose.UNDER_OFFER_SALE]: EstatePurpose.UNDER_OFFER_SALE,
  [EstatePurpose.LIFE_ANNUITY_SALE]: EstatePurpose.LIFE_ANNUITY_SALE,
  [EstatePurpose.SOLD]: EstatePurpose.SOLD
}

export const EstatePurposeMappingNumber = Object.values(EstatePurpose);