import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SetTokenToStoreCommand } from '@commands/ui/set-token-to-store.command';
import { map, Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private http: HttpClient,
    private setTokenToStoreCommand: SetTokenToStoreCommand
  ) {}

  public getToken({
    username,
    password,
  }: {
    username: string;
    password: string;
  }): Observable<any> {
    return this.http.post<{token: string}>(environment.api_path + 'token', {
      username,
      password,
    }).pipe(
      map((token: {token: string}) => token.token),
      tap((token: string) => this.setTokenToStoreCommand.exec(token))
    );
  }

  public getClientToken(): Observable<any> {
    return this.http.post<{token: string}>(environment.api_path + 'v1/admin/clients/token', {
      'ClientId': 626	
    }).pipe(
      map((token: {token: string}) => token.token),
      tap((token: string) => this.setTokenToStoreCommand.exec(token))
    );
  }
}
