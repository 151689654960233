import { Injectable } from "@angular/core";
import { Commands } from "@commands/core/base.command";
import { MenuStore } from "@core/store/menu/menu.store";

@Injectable({
  providedIn: 'root',
})
export class ToggleDrawableMenuCommand implements Commands {
  constructor(private menuStore: MenuStore) {}

  public exec(): void {
    const newValue = this.menuStore.getValue().isOpen;
    this.menuStore.update({ isOpen: !newValue });
  }
}
