export class EmailModel {
  public email!: string;
  public name!: string;
  public phone?: string;
  public content!: string;
  public estate?: string;

  constructor(model: Partial<EmailModel>) {
    Object.assign(this, model);
  }

  public toDto(): any {
    return {
        emailFrom: this.email,
        name: this.name,
        phone: this.phone,
        estate: this.estate,
        content: this.content
    }
  }
}
