<nz-row id="topHeader">
    <nz-col nzLg="20" nzXl="20" nzXs="24" nzSm="24" id="topHeader-container">
        <nz-col nzLg="20" nzXl="20" nzXs="24" nzSm="24" id="topHeader-container-infos">
            <ul>
                <li><a [href]="'tel:'+('COMMON.PHONE_NUMBER' | translate)"><i class="icon-call"></i> {{ 'COMMON.PHONE_NUMBER' | translate }}</a></li>
                <li><a [href]="'mailto:'+('COMMON.EMAIL' | translate)"><i class="icon-mail"></i> {{ 'COMMON.EMAIL' | translate }}</a></li>
            </ul>
        </nz-col>
        <nz-col nzSpan="4" id="topHeader-container-link">
            <a target="_blank" href="https://owner-whise.webulous.be/kAzr2E77iP">Login Propriétaire</a>
        </nz-col>
    </nz-col>
</nz-row>
<nz-header [id]="headerRouting?.name" *ngIf="headerRouting$ | async as headerRouting">
    <nz-row id="headerContainer">
        <nz-col nzSpan="4" id="headerContainer-button"></nz-col>

        <nz-col nzSpan="20" id="headerContainer-name"><a [routerLink]="['/']">SCHMIDT</a></nz-col>

        <div id="headerContainer-menu" nz-col nzSpan="16" nz-menu nzMode="horizontal">
            <a nzMatchRouter nz-menu-item [routerLink]="['/estate']">{{ 'HEADER.NAV.OUR_HOMES' | translate }}</a>
            <a nzMatchRouter nz-menu-item [routerLink]="['/services']">{{ 'HEADER.NAV.SERVICES' | translate }}</a>
            <a nz-menu-item [routerLink]="['/']" id="logo"><img src="/assets/images/logo.svg" alt="Schmidt" /></a>
            <a nzMatchRouter nz-menu-item [routerLink]="['/team']">{{ 'HEADER.NAV.ABOUT' | translate }}</a>
            <a nzMatchRouter nz-menu-item [routerLink]="['/contact']">{{ 'HEADER.NAV.CONTACT' | translate }}</a>
        </div>

        <nz-col id="headerContainer-burger" nzSpan="4" (click)="openDrawerMenu()"><i class="icon-hamburger"></i></nz-col>
    </nz-row>
    <app-sub-header [headerRouting]="headerRouting"></app-sub-header>
</nz-header>