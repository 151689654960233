import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ToggleDrawableMenuCommand } from '@commands/ui/toggle-drawable-menu.command';
import { MenuQuery } from '@core/store/menu/menu.query';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-drawer-menu',
  templateUrl: './drawer-menu.component.html',
  styleUrls: ['./drawer-menu.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DrawerMenuComponent implements OnInit {
  public isDrawerVisible$!: Observable<boolean>;
  public isMobile = this.deviceService.isMobile();

  constructor(
    private toggleDrawableMenuCommand: ToggleDrawableMenuCommand,
    private deviceService: DeviceDetectorService,
    private menuQuery: MenuQuery
  ) {}

  ngOnInit(): void {
    this.isDrawerVisible$ = this.menuQuery.isMenuOpen$;
  }

  public close(): void {
    this.toggleDrawableMenuCommand.exec();
  }
}
