// type
export enum EstateCategory {
  NONE = '',
  HOUSE = 'house',
  FLAT = 'flat',
  PLOT = 'plot',
  OFFICE = 'office',
  COMMERCIAL = 'commercial',
  INDUSTRIAL = 'industrial',
  GARAGE = 'garage / parking'
}

export const EstateCategoryToDto: any = {
  [EstateCategory.HOUSE]: EstateCategory.HOUSE,
  [EstateCategory.FLAT]: EstateCategory.FLAT,
  [EstateCategory.PLOT]: EstateCategory.PLOT,
  [EstateCategory.OFFICE]: EstateCategory.OFFICE,
  [EstateCategory.COMMERCIAL]: EstateCategory.COMMERCIAL,
  [EstateCategory.INDUSTRIAL]: EstateCategory.INDUSTRIAL,
  [EstateCategory.GARAGE]: EstateCategory.GARAGE
}

export const EstateCategoryMappingNumber = Object.values(EstateCategory);