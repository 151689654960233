import { Injectable } from '@angular/core';
import { Commands } from '@commands/core/base.command';
import { EstateSearch } from '@core/models';
import { EstatePagination } from '@core/models/estate-pagination.model';
import { EstateService } from '@core/network/estate.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GetXsellEstateFicheCommand implements Commands {
  constructor(private estateService: EstateService) {}

  public exec(): Observable<EstatePagination> {
    const estateSearch = new EstateSearch({
      page: 0,
      limit: 3,
    });
    return this.estateService.getEstates(estateSearch);
  }
}
