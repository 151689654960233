import {
  EstateCategory,
  EstateCategoryMappingNumber,
  EstatePurpose,
  EstatePurposeMappingNumber,
  PictureOrientation
} from '@core/enums';
import { PictureInterface } from './picture.interface';

export class Estate {
  public id!: number;
  public address!: string;
  public area!: number;
  public bathRooms!: number;
  public box!: string;
  public city!: string;
  public client!: string;
  public clientId!: number;
  public comments!: string;
  public shortDescription!: string;
  public sms!: string;
  public type!: EstateCategory; // maison / apart ..
  public status!: EstatePurpose; // vendre / louer
  public createDateTime!: Date;
  public currency!: string;
  public displayStatusId!: number;
  public documents?: {
    description: string;
    name: string;
    size: number;
    url: string;
  }[];
  public energyClass!: string;
  public furnished!: number;
  public garage!: number;
  public garden!: number;
  public groundArea!: number;
  public maxArea!: number;
  public makeOffer!: boolean;
  public minArea!: number;
  public name!: string;
  public number!: string;
  public office!: string;
  public officeId!: number;
  public option!: 'l'|'v';
  public parking!: number;
  public price!: number;
  public priceChangeDateTime!: Date;
  public putOnlineDateTime!: Date;
  public purposeStatus!: EstatePurpose;
  public referenceNumber!: string;
  public rooms!: number;
  public terrace!: number;
  public updateDateTime!: Date;
  public linkVirtualVisit?: string;
  public zip!: number;
  public pictures!: PictureInterface[];
  public details!: {
    id: number;
    label: string;
    type: string;
    value: boolean | string | number;
  }[];

  constructor(model: Partial<Estate>) {
    Object.assign(this, model);
  }

  private static _checkStatus(id: number): EstatePurpose {
    switch (id) {
      case 6:
        return EstatePurpose.UNDER_OFFER_RENT;
      case 5:
      case 16:
        return EstatePurpose.UNDER_OFFER_SALE;
      case 1:
      case 15:
      case 16:
        return EstatePurpose.FOR_SALE;
      case 2:
        return EstatePurpose.FOR_RENT;
      case 3:
      case 17:
        return EstatePurpose.SOLD;
      default:
        return EstatePurpose.FOR_SALE;
    }
  }

  public static fromDto(dto: any): Estate {
    return new Estate({
      id: dto.id,
      address: dto.address,
      area: dto.area,
      box: dto.box,
      bathRooms: dto.bathRooms,
      city: dto.city,
      client: dto.client,
      clientId: dto.clientId,
      createDateTime: dto.createDateTime,
      currency: dto.currency,
      comments: dto.comments,
      displayStatusId: dto.displayStatusId,
      documents: dto.documents,
      shortDescription: dto.shortDescription ? dto.shortDescription[0].content : null,
      energyClass: dto.energyClass,
      sms: dto.sms ? dto.sms[0].content : null,
      furnished: dto.furnished,
      garage: dto.garage,
      garden: dto.garden,
      groundArea: dto.groundArea,
      maxArea: dto.maxArea,
      makeOffer: dto.makeOffer || false,
      minArea: dto.minArea,
      name: dto.name,
      number: dto.number,
      office: dto.office,
      officeId: dto.officeId,
      option: dto.option,
      parking: dto.parking,
      price: dto.price,
      priceChangeDateTime: dto.priceChangeDateTime,
      putOnlineDateTime: new Date(dto.putOnlineDateTime),
      purposeStatus: this._checkStatus(dto.purposeStatus?.id),
      referenceNumber: dto.referenceNumber,
      rooms: dto.rooms,
      terrace: dto.terrace,
      updateDateTime: dto.updateDateTime,
      type: dto.category?.id ? EstateCategoryMappingNumber[dto.category?.id] : EstateCategory.NONE,
      status: dto.purposeStatus?.id ? EstatePurposeMappingNumber[dto.purposeStatus?.id] : EstatePurpose.NONE,
      zip: dto.zip,
      linkVirtualVisit: dto.linkVirtualVisit,
      pictures: dto.pictures?.map((picture: any) => ({
        order: picture.order,
        orientation: picture.orientation,
        urlLarge: picture.urlLarge,
        urlSmall: picture.urlSmall,
      })),
      details: dto.details?.map((detail: any) => ({
        id: detail.id,
        label: changeLabel(detail.label),
        type: detail.type,
        value: transformValue(detail.label, detail.value),
      })),
    });
  }
}

function changeLabel(label: string): string {
  switch (label) {
    case "Droits d'enregistrements":
      return "Droits d'enregistrements (%)";
    case "PEB (E-SPEC)":
      return "PEB (E-SPEC) (Kwh)";
    case "E total":
      return "E total (Kwh)"
    default:
      return label;
  }
}

function transformValue(label: string, value: any): string | boolean | number {
  switch (label) {
    case 'gaz':
    case 'eau':
    case 'électricité':
    case 'accès handicapés':
    case 'ascenseur':
    case 'toilettes H/F':
    case 'double vitrage':
    case 'Sous régime TVA':
    case 'sécurité':
    case 'égouts':
    case 'loué':
    case 'alarme':
    case 'câbles téléphoniques':
    case 'cuisine':
    case 'bureau':
    case 'piscine':
    case 'Cave':
    case 'Grenier':
      return handleSpecialCases(value);
    default:
      return value;
  }
}

function handleSpecialCases(value: any): string | boolean | number {
  switch (value) {
    case '0':
      return 'Non';
    case '1':
      return 'Oui';
    default:
      return value;
  }
}
