import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HeaderTypeEnum } from '@core/enums';
import { HeaderRoutingInterface } from '@core/models/header-routing.interface';

const routes: Routes = [
  {
    path: '',
    data: {
      header: {
        name: 'HOME',
        type: HeaderTypeEnum.BASIC,
      } as HeaderRoutingInterface,
    },
    loadChildren: () =>
      import('./modules/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'estate',
    data: {
      header: {
        name: 'SEARCH',
        type: HeaderTypeEnum.BASIC,
      } as HeaderRoutingInterface,
    },
    loadChildren: () =>
      import('./modules/search/search.module').then((m) => m.SearchModule),
  },
  {
    path: 'estate',
    data: {
      header: {
        name: 'FICHE',
        type: HeaderTypeEnum.FICHE,
      } as HeaderRoutingInterface,
    },
    loadChildren: () =>
      import('./modules/fiche/fiche.module').then((m) => m.FicheModule),
  },
  {
    path: 'services',
    data: {
      header: {
        name: 'SERVICES',
        type: HeaderTypeEnum.BASIC,
      } as HeaderRoutingInterface,
    },
    loadChildren: () =>
      import('./modules/services/services.module').then(
        (m) => m.ServicesModule
      ),
  },
  {
    path: 'team',
    data: {
      header: {
        name: 'ABOUT',
        type: HeaderTypeEnum.BASIC,
      } as HeaderRoutingInterface,
    },
    loadChildren: () =>
      import('./modules/about/about.module').then((m) => m.AboutModule),
  },
  {
    path: 'contact',
    data: {
      header: {
        name: 'CONTACT',
        type: HeaderTypeEnum.BASIC,
      } as HeaderRoutingInterface,
    },
    loadChildren: () =>
      import('./modules/contact/contact.module').then((m) => m.ContactModule),
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking',
    scrollPositionRestoration: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
