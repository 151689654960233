<nz-drawer
    [nzClosable]="true"
    [nzVisible]="(isDrawerVisible$ | async)!"
    nzPlacement="right"
    
    nzTitle="SCHMIDT"
    (nzOnClose)="close()"
    >
    <ng-container *nzDrawerContent>
        <ng-container  *ngIf="!isMobile">
            <ul id="menu-infos">
                <li><a [href]="'tel:'+('COMMON.PHONE_NUMBER' | translate)">T. {{ 'COMMON.PHONE_NUMBER' | translate }}</a></li>
                <li><a [href]="'mailto:'+('COMMON.EMAIL' | translate)">{{ 'COMMON.EMAIL' | translate }}</a></li>
            </ul>

            <app-agence-location-list></app-agence-location-list>
        </ng-container>

        <ul id="menu-list" *ngIf="isMobile">
            <li (click)="close()" [routerLink]="['/estate']">{{ 'HEADER.NAV.OUR_HOMES' | translate }}</li>
            <li (click)="close()" [routerLink]="['/services']">{{ 'HEADER.NAV.SERVICES' | translate }}</li>
            <li (click)="close()" [routerLink]="['/team']">{{ 'HEADER.NAV.ABOUT' | translate }}</li>
            <li (click)="close()" [routerLink]="['/contact']">{{ 'HEADER.NAV.CONTACT' | translate }}</li>
        </ul>

        <div id="drawer-buttons" nz-row>
            <button (click)="close()" nz-col nzSpan="24" [routerLink]="['/contact']" [nzSize]="'large'" nz-button nzType="primary" nzShape="round">{{ 'HEADER.ACTIONS.ESTIMATE_HOME' | translate }}</button>
            <button (click)="close()" nz-col nzSpan="24" [routerLink]="['/contact']" [nzSize]="'large'" nz-button nzType="primary" nzShape="round">{{ 'COMMON.TAKE_RDV' | translate }}</button>
            <a nz-col nzSpan="24" target="_blank" href="https://owner-whise.webulous.be/kAzr2E77iP" nzGhost [nzSize]="'large'" nz-button nzType="primary" nzShape="round">{{ 'COMMON.LOGIN_PROPRIO' | translate }}</a>
        </div>
    </ng-container>
</nz-drawer>