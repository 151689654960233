import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { MenuState, MenuStore } from './menu.store';

@Injectable({
  providedIn: 'root',
})
export class MenuQuery extends Query<MenuState> {
  isMenuOpen$ = this.select('isOpen');

  constructor(store: MenuStore) {
    super(store);
  }
}
