<nz-row id="footer-review">
    <nz-col nzSpan="24">
        <h3>Des clients satisfaits !</h3>
    </nz-col>
    <nz-col nzLg="12" nzXl="12" nzXs="24" nzSm="24" id="footer-review-left">
        <nz-row>
            <nz-col nzSpan="3">
                <img src="/assets/images/google-logo.svg" alt="Schmidt google review" />
            </nz-col>
            <nz-col nzSpan="19">
                <div><i class="icon-review-full"></i><i class="icon-review-full"></i><i class="icon-review-full"></i><i class="icon-review-full"></i><i class="icon-review-demi"></i></div>
                <p id="bulle">Efficacité Serviabilité 2 qualificatifs évidents pour cette agence 🌈 Toujours à l ' écoute et réactifs . A...</p>
                <p>CAROLE GILON</p>
            </nz-col>
        </nz-row>
    </nz-col>
    <nz-col nzLg="12" nzXl="12" nzXs="24" nzSm="24" id="footer-review-both">
        <p class="review-total"><img src="/assets/images/google-logo.svg" alt="Schmidt google review" /> 
            <span><i class="icon-review-full"></i><i class="icon-review-full"></i><i class="icon-review-full"></i><i class="icon-review-full"></i><i class="icon-review-demi"></i></span>
            <span>4,6 étoiles basées sur <span>80 avis</span></span>
        </p>
        <p class="review-total"><img src="/assets/images/facebook-logo.svg" alt="Schmidt facebook review" /> 
            <span><i class="icon-review-full"></i><i class="icon-review-full"></i><i class="icon-review-full"></i><i class="icon-review-full"></i><i class="icon-review-demi"></i></span>
            <span>4,8 étoiles basées sur <span>43 avis</span></span>
        </p>
    </nz-col>
</nz-row>