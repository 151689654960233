import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface MenuState {
  isOpen: boolean;
}

export function createInitialState(): MenuState {
  return {
    isOpen: false
  };
}

@StoreConfig({ name: 'menu' })
@Injectable({
  providedIn: 'root',
})
export class MenuStore extends Store<MenuState> {
  constructor() {
    super(createInitialState());
  }
}
