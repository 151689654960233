
export class EstateLocation {
  public name!: string;
  public zip!: string;

  constructor(model: Partial<EstateLocation>) {
    Object.assign(this, model);
  }

  public static fromDto(dto: any): EstateLocation {
    return new EstateLocation({
      name: dto.name,
      zip: dto.zip
    });
  }
}