import { Injectable } from '@angular/core';
import { Commands } from '@commands/core/base.command';
import { EmailModel } from '@core/models';
import { EmailService } from '@core/network/email.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SendEmailContactCommand implements Commands {
  constructor(private emailService: EmailService) {}

  public exec(email: EmailModel): Observable<void> {
      return this.emailService.sendEmailContact(email);
  }
}
